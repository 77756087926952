@import '../styles/defaults';

.footer-ep {
    z-index: 1;
    position: relative !important;
    padding: 20px 20px 0 20px;
    background: $footer-background !important;
    text-align: center;
    font-size: 16px !important;
    color: $footer-color !important;
    height: 88px !important;
    left: 0;
    bottom: 0;
    right: 0;
    a {
        color: $footer-link-color;
        font-weight: bold;
        &:hover {
            color: $footer-link-color-over;
        }
    }

    @media (max-width: 640px) {
        height: 55px !important;
    }
}
