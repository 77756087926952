html,
body {
  min-width: unset !important;
  overflow: auto !important;
}

#zmmtg-root {
  #wc-content {
    padding-top: 45px;
    padding-bottom: 88px;

    button {
      padding: 2px 15px;
    }

    .meeting-info-container {
      top: 57px;
    }

    .full-screen-widget__pop-menu.dropdown-menu {
      left: unset;
    }

    .full-screen-widget__button {
      background-color: transparent;
      color: #fff;
      display: flex;
    }

    #wc-container-left,
    #wc-container-right {

      div {
        max-height: calc(100vh - 45px - 88px - 120px - 52px) !important;
      }

      .gallery-video-container__wrap {
        margin-top: -90px;
      }

      .speaker-view {
        text-align: center;
        padding-top: 50px;
      }

      canvas {
        &:not(.gallery-video-container__canvas) {
          max-height: calc(100vh - 45px + 50px - 88px - 120px - 52px) !important;
          position: relative;

          @media (max-width: 640px) {
            max-height: calc(100vh - 45px - 55px - 120px - 52px) !important;
          }
        }
      }
    }
  }


  #wc-footer {
    padding: 0;
  }
}